const state = () => ({
    scrollPosition: 0,
    csrfToken: '',
    siteUrl: '',
})

// Getters
const getters = {
    scrollPosition: (state, getters) => {
        return state.scrollPosition
    }
}

// Actions
const actions = {
}

// Mutations
const mutations = {
    setCsrfToken(state, token) {
        state.csrfToken = token
    },
    setSiteUrl(state, value) {
        state.siteUrl = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
