// Import
import { createApp, defineAsyncComponent } from "vue"

// Components
import store from './store'
import VueClickAway from "vue3-click-away";

// Declare
declare const window: any;

// App main
const main = async () => {
    const app = createApp({
        delimiters: ['${', '}'],
        components: {
			AccordionFrontpage: defineAsyncComponent(() => import(/* webpackChunkName: "AccordionFrontpage" */ '@/sites/intranet/js/components/parts/AccordionFrontpage.vue')),
			SignupButton: defineAsyncComponent(() => import(/* webpackChunkName: "SignupButton" */ '@/sites/intranet/js/components/parts/SignupButton.vue')),
			PostButton: defineAsyncComponent(() => import(/* webpackChunkName: "PostButton" */ '@/sites/intranet/js/components/parts/PostButton.vue')),
			SemesterProgress: defineAsyncComponent(() => import(/* webpackChunkName: "SemesterProgress" */ '@/sites/intranet/js/components/SemesterProgress.vue')),
			ProgressBar: defineAsyncComponent(() => import(/* webpackChunkName: "ProgressBar" */ '@/sites/intranet/js/components/parts/ProgressBar.vue')),
			TabTable: defineAsyncComponent(() => import(/* webpackChunkName: "TabTable" */ '@/sites/intranet/js/components/TabTable.vue')),
			Banner: defineAsyncComponent(() => import(/* webpackChunkName: "Banner" */ '@/sites/intranet/js/components/parts/Banner.vue')),
			InfoAccordion: defineAsyncComponent(() => import(/* webpackChunkName: "InfoAccordion" */ '@/sites/intranet/js/components/parts/InfoAccordion.vue')),
			ProfileCircle: defineAsyncComponent(() => import(/* webpackChunkName: "ProfileCircle" */ '@/sites/intranet/js/components/ProfileCircle.vue')),
			UserProfile: defineAsyncComponent(() => import(/* webpackChunkName: "UserProfile" */ '@/sites/intranet/js/components/UserProfile.vue')),
			CoursesTable: defineAsyncComponent(() => import(/* webpackChunkName: "CoursesTable" */ '@/sites/intranet/js/components/CoursesTable.vue')),
			BurgerMenu: defineAsyncComponent(() => import(/* webpackChunkName: "BurgerMenu" */ '@/sites/intranet/js/components/BurgerMenu.vue')),
        },
        data: () => ({
		}),
        methods: {
        },
		created() {
		},
		destroyed() {
		},
	   beforeMount() {
			//Get sitedata
			store.commit('app/setSiteUrl', window.siteData.siteUrl)
			store.commit('app/setCsrfToken', window.siteData.csrf)
	   },
	   mounted() {
	   }

    });

	app.use(store);
	app.use(VueClickAway)
    // Mount the app
    const vm = app.mount('#app');
    return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
